<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <div class="fl">
            <span class="font16"><b>调用统计（{{count}}个）</b></span>
          </div>
          <div class="fr">
            <custom-date-picker v-model="date" @getDate="getDate"></custom-date-picker>
          </div>
        </div>
        <div class="department-content auto">
          <div class="box auto">
            <div class="box-title auto">
              <div class="fl">各应用调用量（Top 10）</div>
            </div>
            <div class="box-con auto">
              <div style="width: 500px;">
                <echarts-base :data="chartData" :height="300"></echarts-base>
              </div>
            </div>
          </div>
          <div class="box box2 auto">
            <div class="box-title auto">
              <div class="fl">应用调用统计</div>
            </div>
            <div class="box-con auto no-bor">
              <div class="m-t-10">
                <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false" @change="changeTable">
                  <span slot="index" slot-scope="text,record,index">{{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}</span>
                </a-table>
              </div>
              <div class="m-t-10">
                <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size"></custom-page>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
import { Data as DataController } from '@/controller'
export default {
  data () {
    return {
      loading: false,
      date: [],
      chartData: '',
      tablecolumns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          key: 'index'
        },
        {
          title: '应用名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '调用次数',
          dataIndex: 'count',
          scopedSlots: { customRender: 'count' },
          key: 'count',
          sorter: true,
          // sortOrder: 'descend',
          sortDirections: ['ascend', 'descend']
        }
      ],
      tabledata: [],
      filter: {
        page_size: 10,
        current_page: 1,
        order: ''
      },
      count: 0
    }
  },
  methods: {
    changeTable (pagination, filters, sorter, { currentDataSource }) {
      this.filter['current_page'] = 1
      if (sorter.order) {
        var word = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.filter['order'] = word
      } else {
        this.filter['order'] = ''
      }
      this.getAppStatisticList()
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getAppStatisticList()
    },
    getDate (date) {
      this.date = [date[0], date[1]]
      this.filter = {
        page_size: 10,
        current_page: 1,
        order: ''
      }
      this.getAppStatistic()
      this.getAppStatisticList()
    },
    async getAppStatistic () {
      this.loading = true
      let res = await System.getAllAppStatistic({
        startDate: this.date[0],
        endDate: this.date[1]
      })
      this.loading = false
      if (!res['code']) {
        this.chartData = DataController.System.Pie9(res['data'].length ? res['data'] : '')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getAppStatisticList () {
      this.loading = true
      let res = await System.getAllAppStatisticList({
        startDate: this.date[0],
        endDate: this.date[1],
        current_page: this.filter.current_page,
        page_size: this.filter.page_size,
        order: this.filter.order
      })
      this.loading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    // this.getAppStatistic()
    // this.getAppStatisticList()
  },
  mounted () {
  }
}
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}
</style>
